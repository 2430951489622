<script lang="ts">
  import type { HTMLAttributes } from 'svelte/elements';
  import Icon from '$lib/components/icon/icon.svelte';
  import Text from '$lib/components/text/text.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import type { Card } from '$lib/contentful/models/card';
  import { iconVariants } from '$lib/contentful/models/theme';
  import Button from '../button/button.svelte';

  type $$Props = HTMLAttributes<HTMLDivElement> & {
    class?: never;
    content: Card;
  };

  export let content: Card;

  $: ({
    heading,
    body,
    icon,
    iconVariant = 'default',
    image,
    link,
    linkText,
    eyebrow,
    entityId,
    theme = 'ultraviolet',
    callToAction,
  } = content);

  // maintain a 16:9 ratio
  const imgWidth = 16 * 40;
  const imgHeight = 9 * 40;
</script>

<div class="card {theme}" data-contentful-entry-id={entityId}>
  {#if image !== null && image?.url && image?.description}
    <ContentfulImage
      {entityId}
      fieldId="image"
      src={image.url}
      alt={image.description}
      width={imgWidth}
      height={imgHeight}
      fitStrategy="fill"
      class="rounded-t-2xl"
    />
  {/if}
  <div class="flex h-full flex-col gap-6 justify-between">
    <div class="card-content">
      {#if eyebrow}
        <Text as="p" variant="eyebrow-16" class="text-mint">
          {eyebrow}
        </Text>
      {/if}
      {#if icon}
        <Icon
          name={icon}
          variant={iconVariant === 'default'
            ? iconVariants[theme]
            : iconVariant}
          {entityId}
          fieldId="icon"
          height={32}
          width={32}
        />
      {/if}
      {#if heading}
        <Text
          class="text-white"
          variant="heading-32"
          {entityId}
          fieldId="heading"
        >
          {heading}
        </Text>
      {/if}
      {#if body}
        <RichText
          theme="dark"
          listStyle="checkmark"
          text={body}
          fieldId="body"
          {entityId}
        />
      {/if}
      <slot />
    </div>
    {#if callToAction || link}
      <div class="flex items-center px-6 gap-4 pb-8">
        {#if callToAction}
          {@const { text, ...rest } = callToAction}
          <Button {...rest} {theme}>{text}</Button>
        {/if}

        {#if link && linkText}
          <Button
            align="left"
            variant="ghost"
            href={link}
            {theme}
            trailingIcon="arrow-right"
          >
            {linkText}
          </Button>
        {/if}
      </div>
    {/if}
  </div>
</div>

<style lang="postcss">
  .card {
    @apply h-full w-auto flex flex-col bg-space-black border rounded-2xl;

    &.off-white {
      @apply border-graphite;
    }

    &.ultraviolet {
      @apply border-transparent border-gradient-ultraviolet;
    }

    &.green {
      @apply border-graphite;
    }

    &.pink-gradient {
      @apply border-transparent border-gradient-pink;
    }

    &.mist {
      @apply border-transparent border-gradient-mist;
    }

    &.pink {
      @apply border-bubble-gum;
    }
  }

  .card-content {
    @apply flex flex-col gap-4 p-6;
  }
</style>
